// extracted by mini-css-extract-plugin
export var Conditions = "joinUs-module--Conditions--VuYst";
export var ConditionsBlock = "joinUs-module--ConditionsBlock--CC-46";
export var ConditionsBlock_icon = "joinUs-module--ConditionsBlock_icon--4IErS";
export var ConditionsBlock_imgWrapper = "joinUs-module--ConditionsBlock_imgWrapper--dxD44";
export var ConditionsBlock_secretCircle = "joinUs-module--ConditionsBlock_secretCircle--MkqpR";
export var ConditionsBlock_text = "joinUs-module--ConditionsBlock_text--TAUk-";
export var ConditionsBlock_title = "joinUs-module--ConditionsBlock_title--2JIqf";
export var Conditions_grid = "joinUs-module--Conditions_grid--2zrQp";
export var Conditions_preTitle = "joinUs-module--Conditions_preTitle--wy1CY";
export var Conditions_title = "joinUs-module--Conditions_title--KFOs3";
export var ExtraConditions = "joinUs-module--ExtraConditions--qzwSA";
export var ExtraConditions_grid = "joinUs-module--ExtraConditions_grid--xkLDR";
export var ExtraConditions_title = "joinUs-module--ExtraConditions_title--KcKHd";
export var HeaderSection_icons = "joinUs-module--HeaderSection_icons--gSFA9";