import * as React from "react";
import { useState } from "react";
import * as style from "./styles/joinUs.module.scss";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { getImage } from "gatsby-plugin-image";
import { AnimatePresence, motion } from "framer-motion";
import Masonry from "react-masonry-css";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import BoundedSection from "components/BoundedSection";
import IntroSection from "components/IntroSection";
import Testimonial from "components/Testimonial";
import Cta from "components/Cta";
import { mobileBreakpoint } from "components/Mediaqueries";
import MorphingScrollImg from "components/MorphingScrollImg";
import JoinUsModal from "components/JoinUsModal";
import { useDisableBodyScroll } from "../utils/useDisableBodyScroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import BeachAccessTwoToneIcon from "@mui/icons-material/BeachAccessTwoTone";
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import PollTwoToneIcon from "@mui/icons-material/PollTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import InsertEmoticonTwoToneIcon from "@mui/icons-material/InsertEmoticonTwoTone";
import LaptopMacTwoToneIcon from "@mui/icons-material/LaptopMacTwoTone";
import CoffeeTwoToneIcon from "@mui/icons-material/CoffeeTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";

const ConditionsSection = ({ preTitle, title, children }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection className={style.Conditions}>
      <h4 className={style.Conditions_preTitle}>{preTitle}</h4>
      <h3 className={style.Conditions_title}>{title}</h3>
      <Masonry
        breakpointCols={isMobile ? 1 : 2}
        className={style.Conditions_grid}
      >
        {children}
      </Masonry>
    </BoundedSection>
  );
};

const iconVariants = {
  initial: { scale: 0 },
  inView: {
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      delay: 0.5,
      duration: 0.5,
    },
  },
};

const secretCircleVariants = {
  initial: { scale: 0 },
  inView: {
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      delay: 0.5,
      duration: 0.5,
    },
  },
};

const ConditionsBlock = ({ icon, title, children, img, secretCircle, id }) => {
  const { ref, inView } = useInView({
    threshold: 0.8,
  });
  return (
    <div className={style.ConditionsBlock} ref={ref} id={id}>
      <MorphingScrollImg
        className={style.ConditionsBlock_imgWrapper}
        img={img}
        alt={title}
      >
        <motion.div
          className={style.ConditionsBlock_icon}
          variants={iconVariants}
          initial="initial"
          animate={inView ? "inView" : "initial"}
        >
          {icon}
        </motion.div>
        {secretCircle && (
          <motion.div
            className={style.ConditionsBlock_secretCircle}
            variants={secretCircleVariants}
            initial="initial"
            whileInView="inView"
          >
            {secretCircle}
          </motion.div>
        )}
      </MorphingScrollImg>

      <div className={style.ConditionsBlock_text}>
        <h4 className={style.ConditionsBlock_title}>{title}</h4>
        {children}
      </div>
    </div>
  );
};

const WorkdayConditions = ({ t, data }) => {
  return (
    <ConditionsSection
      preTitle={t("joinUs.conditions.workday.preTitle")}
      title={t("joinUs.conditions.workday.title")}
    >
      <ConditionsBlock
        id="schedule"
        title={t("joinUs.conditions.workday.schedule.title")}
        icon={<QueryBuilderTwoToneIcon />}
        img={data && getImage(data.scheduleImg)}
      >
        <Trans
          i18nKey="joinUs.conditions.workday.schedule.description"
          components={{ em: <em /> }}
        />
      </ConditionsBlock>
      <ConditionsBlock
        id="remote"
        title={t("joinUs.conditions.workday.remote.title")}
        icon={<HomeTwoToneIcon />}
        img={data && getImage(data.remoteImg)}
      >
        <Trans i18nKey="joinUs.conditions.workday.remote.description" />
      </ConditionsBlock>
      <ConditionsBlock
        id="holidays"
        title={t("joinUs.conditions.workday.holidays.title")}
        icon={<BeachAccessTwoToneIcon />}
        img={data && getImage(data.holidaysImg)}
      >
        <Trans i18nKey="joinUs.conditions.workday.holidays.description" />
      </ConditionsBlock>
    </ConditionsSection>
  );
};

const LearningConditions = ({ t, data }) => {
  return (
    <ConditionsSection
      preTitle={t("joinUs.conditions.learning.preTitle")}
      title={t("joinUs.conditions.learning.title")}
    >
      <ConditionsBlock
        id="platforms"
        title={t("joinUs.conditions.learning.platforms.title")}
        icon={<LocalLibraryTwoToneIcon />}
        img={data && getImage(data.learningPlatformsImg)}
      >
        <Trans
          i18nKey="joinUs.conditions.learning.platforms.description"
          components={{
            a: <a data-cursor-size="48px" data-cursor-color="#ffffff20" />,
          }}
        />
      </ConditionsBlock>
      <ConditionsBlock
        id="pills"
        title={t("joinUs.conditions.learning.pills.title")}
        icon={<SchoolTwoToneIcon />}
        img={data && getImage(data.pillsImg)}
      >
        <Trans
          i18nKey="joinUs.conditions.learning.pills.description"
          components={{ em: <em /> }}
        />
      </ConditionsBlock>
    </ConditionsSection>
  );
};

const MiscConditions = ({ t, data }) => {
  return (
    <ConditionsSection
      preTitle={t("joinUs.conditions.transparency.preTitle")}
      title={t("joinUs.conditions.transparency.title")}
    >
      <ConditionsBlock
        id="transparency"
        title={t("joinUs.conditions.transparency.transparency.title")}
        icon={<PollTwoToneIcon />}
        img={data && getImage(data.transparencyImg)}
      >
        <Trans
          i18nKey="joinUs.conditions.transparency.transparency.description"
          components={{ em: <em /> }}
        />
      </ConditionsBlock>
      <ConditionsBlock
        id="salary"
        title={t("joinUs.conditions.transparency.salary.title")}
        icon={<PaymentsTwoToneIcon />}
        img={data && getImage(data.salaryImg)}
      >
        <Trans i18nKey="joinUs.conditions.transparency.salary.description" />
      </ConditionsBlock>
      <ConditionsBlock
        id="communication"
        title={t("joinUs.conditions.transparency.comunication.title")}
        icon={<ForumTwoToneIcon />}
        img={data && getImage(data.careImg)}
      >
        <Trans
          i18nKey="joinUs.conditions.transparency.comunication.description"
          components={{ em: <em /> }}
        />
      </ConditionsBlock>
    </ConditionsSection>
  );
};

const ExtraConditions = ({ t }) => {
  return (
    <BoundedSection className={style.ExtraConditions}>
      <h3 className={style.ExtraConditions_title}>
        {t("joinUs.conditions.extra.title")}
      </h3>
      <div className={style.ExtraConditions_grid}>
        <div>
          <CoffeeTwoToneIcon />
          <Trans i18nKey="joinUs.conditions.extra.1" />
        </div>
        <div>
          <LaptopMacTwoToneIcon />
          <Trans
            i18nKey="joinUs.conditions.extra.2"
            components={{ em: <em /> }}
          />
        </div>
        <div>
          <InsertEmoticonTwoToneIcon />
          <Trans i18nKey="joinUs.conditions.extra.3" />
        </div>
      </div>
    </BoundedSection>
  );
};

const AnchorIcon = ({ icon, title, anchorId }) => (
  <AnchorLink to={anchorId} stripHash>
    <div data-cursor-size="48px" data-cursor-color="#ffffff20">
      {icon}
    </div>
  </AnchorLink>
);

const JoinUsPage = ({ data }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  useDisableBodyScroll(modalOpen);
  return (
    <PageWrapper
      seoTitle={t("joinUs.seo.title")}
      seoDescription={t("joinUs.seo.description")}
    >
      <HeaderSection
        preTitle={t("joinUs.header.preTitle")}
        title={<Trans i18nKey="joinUs.header.title" />}
        description={
          <div className={style.HeaderSection_icons}>
            <AnchorIcon
              icon={<QueryBuilderTwoToneIcon />}
              anchorId="#schedule"
            />
            <AnchorIcon icon={<HomeTwoToneIcon />} anchorId="#remote" />
            <AnchorIcon
              icon={<BeachAccessTwoToneIcon />}
              anchorId="#holidays"
            />
            <AnchorIcon
              icon={<LocalLibraryTwoToneIcon />}
              anchorId="#platforms"
            />
            <AnchorIcon icon={<SchoolTwoToneIcon />} anchorId="#pills" />
            <AnchorIcon icon={<PollTwoToneIcon />} anchorId="#transparency" />
            <AnchorIcon icon={<PaymentsTwoToneIcon />} anchorId="#salary" />
            <AnchorIcon icon={<ForumTwoToneIcon />} anchorId="#communication" />
          </div>
        }
      />
      <IntroSection>
        <Trans i18nKey="joinUs.intro" />
      </IntroSection>
      <WorkdayConditions t={t} data={data} />
      <LearningConditions t={t} data={data} />
      <MiscConditions t={t} data={data} />
      <ExtraConditions t={t} />
      <Testimonial
        img={data && getImage(data.testimonial)}
        quote={t("joinUs.testimonial.quote")}
        author="César Suárez"
        job={t("joinUs.testimonial.authorJob")}
      />
      <Cta
        isSticky
        title={
          <Trans i18nKey="joinUs.cta.title" components={{ span: <span /> }} />
        }
        description={t("joinUs.cta.description")}
        btn={<EmailTwoToneIcon />}
        onBtnClick={() => (modalOpen ? close() : open())}
        stickyTitle={<Trans i18nKey="joinUs.cta.stickyTitle" />}
      />
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {modalOpen && <JoinUsModal modalOpen={modalOpen} handleClose={close} />}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default JoinUsPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    scheduleImg: file(relativePath: { eq: "join_us/schedule.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    remoteImg: file(relativePath: { eq: "join_us/remote.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    holidaysImg: file(relativePath: { eq: "join_us/holidays.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    learningPlatformsImg: file(
      relativePath: { eq: "join_us/learning_platforms.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    pillsImg: file(relativePath: { eq: "join_us/pills.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    transparencyImg: file(relativePath: { eq: "join_us/transparency.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    salaryImg: file(relativePath: { eq: "join_us/salary.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    careImg: file(relativePath: { eq: "join_us/care.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    testimonial: file(relativePath: { eq: "misc/csuarez_quote.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;
