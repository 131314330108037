import * as React from "react";
import * as style from "./testimonial.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import BoundedSection from "../BoundedSection";
import Parallax from "../Parallax";

import FormatQuoteTwoToneIcon from "@mui/icons-material/FormatQuoteTwoTone";

const Testimonial = ({ img, imgAlt = "", quote, author, job }) => {
  return (
    <BoundedSection
      className={style.Testimonial}
      boundedClassName={style.Testimonial_wrapper}
    >
      <Parallax distance="0.05" className={style.Testimonial_img}>
        <div>
          <GatsbyImage image={img} alt={imgAlt} />
        </div>
      </Parallax>

      <div className={style.Testimonial_text}>
        <FormatQuoteTwoToneIcon />
        <p className={style.Testimonial_quote}>{quote}</p>
        <div className={style.Testimonial_author}>
          <p className={style.Testimonial_authorName}>{author}</p>
          <p className={style.Testimonial_authorJob}>{job}</p>
        </div>
      </div>
    </BoundedSection>
  );
};

export default Testimonial;
