import * as React from "react";
import * as style from "./joinUsModal.module.scss";
import { StaticQuery, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";

const backSlide = {
  hidden: {
    translateY: "100%",
  },
  visible: {
    translateY: 0,
  },
};

const contentFade = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Backdrop = ({ children, onClick }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          joinUs: file(relativePath: { eq: "misc/team.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      `}
      render={(data) => (
        <motion.div
          onClick={onClick}
          className={style.ModalOverlay}
          variants={backSlide}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.5, type: "ease" }}
        >
          <GatsbyImage
            className={style.ModalOverlay_img}
            image={data && getImage(data.joinUs)}
            alt=""
          />
          {children}
        </motion.div>
      )}
    />
  );
};

const JoinUsModal = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={style.JoinUsModal}
        variants={contentFade}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{ duration: 0.75, type: "ease" }}
      >
        <h3 className={style.JoinUsModal_title}>{t("joinUsModal.title")}</h3>
        <p className={style.JoinUsModal_description}>
          {t("joinUsModal.description")}
        </p>
        <a
          href="mailto:joinus@trileucosolutions.com"
          className={style.JoinUsModal_btn}
        >
          <EmailTwoToneIcon />
          joinus@trileucosolutions.com
        </a>
      </motion.div>
      <button className={style.JoinUsModal_closeBtn} onClick={handleClose}>
        <div>
          <span></span>
          <span></span>
        </div>
      </button>
    </Backdrop>
  );
};

export default JoinUsModal;
